import {
  all,
  call,
  put,
  StrictEffect,
  take,
  takeLatest
} from 'redux-saga/effects';
import api from 'src/services/api';
import { SoftwaresTypes } from './types';
import * as Actions from './actions';

export function* getSoftwares() {
  try {
    const response = yield call(api.get, '/softwares');
    console.log('response | softwares', response.data);

    yield put(Actions.loadSoftwaresSuccess(response?.data));
  } catch (error) {
    yield put(Actions.loadSoftwaresFailure());
  }
}

export function* getSoftware(payload) {
  console.log('Payload get category', payload);

  try {
    const response = yield call(api.get, `/category/${payload.payload.id}`);
    console.log('response | category', response.data);

    yield put(Actions.loadSoftwareSuccess(response?.data));
  } catch (error) {
    yield put(Actions.loadSoftwareFailure());
  }
}

export function* saveSoftware(payload: any) {
  const image = payload.payload.software;
  const body = {
    ...payload.payload
  };

  body.software = undefined;

  try {
    const response = yield call(
      api.put,
      `/software/${payload?.payload?.id}`,
      {
        body: JSON.stringify(payload.payload),
        software: image
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    console.log('RESPOSTA SAVE_SOFTWARE', response.data);

    yield put(Actions.saveSoftwareSuccess(response.data));
  } catch (error) {
    yield put(Actions.saveSoftwareFailure());
  }
}

export function* insertSoftware(payload: any) {

    const image = payload.payload.software;
    const body = {
      ...payload.payload
    };
  
    body.software = undefined;

    console.log("body", body);
    console.log("image",image);
    
  try {
    const response = yield call(
        api.post,
        `/software`,
        {
          body: JSON.stringify(payload.payload),
          software: image
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
    console.log('RESPOSTA INSERT_SOFTWARE', response.data);

    yield put(Actions.insertSoftwareSuccess(response.data));
  } catch (error) {
    yield put(Actions.insertSoftwareFailure());
  }
}

export function* deleteSoftware(payload: any) {
  try {
    const response = yield call(
      api.delete,
      `/software/${payload?.payload?.id}`
    );
    console.log('RESPOSTA DELETE_SOFTWARE', response.data);

    yield put(Actions.deleteSoftwareSuccess(response.data));
  } catch (error) {
    yield put(Actions.deleteSoftwareFailure());
  }
}

export default function* softwaresSagas(): Generator<StrictEffect> {
  yield all([
    takeLatest(SoftwaresTypes.LOAD_SOFTWARES, getSoftwares),
    takeLatest(SoftwaresTypes.LOAD_SOFTWARE, getSoftware),
    takeLatest(SoftwaresTypes.SAVE_SOFTWARE, saveSoftware),
    takeLatest(SoftwaresTypes.DELETE_SOFTWARE, deleteSoftware),
    takeLatest(SoftwaresTypes.INSERT_SOFTWARE, insertSoftware)
  ]);
}
