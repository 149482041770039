import {all, fork, StrictEffect, takeLatest } from 'redux-saga/effects'
import generalSagas from './general/sagas'
import categoriesSagas from './categories/sagas'
import bannersSagas from './banners/sagas'
import softwaresSagas from './softwares/sagas'
import projectsSagas from './projects/sagas'
import loginSagas from './login/sagas'

export default function* rootSaga(): Generator<StrictEffect> {
    return yield all([
        fork(generalSagas),
        fork(categoriesSagas),
        fork(bannersSagas),
        fork(softwaresSagas),
        fork(projectsSagas),
        fork(loginSagas)
    ])
}
