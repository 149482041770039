import { action } from 'typesafe-actions'
import {CategoriesTypes} from './types'

// Categories
export const loadCategories = () => action(CategoriesTypes.LOAD_CATEGORIES);
export const loadCategoriesSuccess = (payload: any) => action(CategoriesTypes.LOAD_CATEGORIES_SUCCESS, payload);
export const loadCategoriesFailure = () => action(CategoriesTypes.LOAD_CATEGORIES_FAILURE);

export const loadCategory = (payload: any) => action(CategoriesTypes.LOAD_CATEGORY, payload);
export const loadCategorySuccess = (payload: any) => action(CategoriesTypes.LOAD_CATEGORY_SUCCESS, payload);
export const loadCategoryFailure = () => action(CategoriesTypes.LOAD_CATEGORY_FAILURE);

export const saveCategory = (payload: any) => action(CategoriesTypes.SAVE_CATEGORY, payload);
export const saveCategorySuccess = (payload: any) => action(CategoriesTypes.SAVE_CATEGORY_SUCCESS, payload);
export const saveCategoryFailure = () => action(CategoriesTypes.SAVE_CATEGORY_FAILURE);

export const deleteCategory = (payload: any) => action(CategoriesTypes.DELETE_CATEGORY, payload);
export const deleteCategorySuccess = (payload: any) => action(CategoriesTypes.DELETE_CATEGORY_SUCCESS, payload);
export const deleteCategoryFailure = () => action(CategoriesTypes.DELETE_CATEGORY_FAILURE);

export const insertCategory = (payload: any) => action(CategoriesTypes.INSERT_CATEGORY, payload);
export const insertCategorySuccess = (payload: any) => action(CategoriesTypes.INSERT_CATEGORY_SUCCESS, payload);
export const insertCategoryFailure = () => action(CategoriesTypes.INSERT_CATEGORY_FAILURE);