export enum BannersTypes {
    'LOAD_BANNERS' = '@mioca/LOAD_BANNERS',
    'LOAD_BANNERS_SUCCESS' = '@mioca/LOAD_BANNERS_SUCCESS',
    'LOAD_BANNERS_FAILURE' = '@mioca/LOAD_BANNERS_FAILURE',
	'DELETE_BANNERS' = '@mioca/DELETE_BANNERS',
    'DELETE_BANNERS_SUCCESS' = '@mioca/DELETE_BANNERS_SUCCESS',
    'DELETE_BANNERS_FAILURE' = '@mioca/DELETE_BANNERS_FAILURE',
	'SAVE_BANNERS' = '@mioca/SAVE_BANNERS',
    'SAVE_BANNERS_SUCCESS' = '@mioca/SAVE_BANNERS_SUCCESS',
    'SAVE_BANNERS_FAILURE' = '@mioca/SAVE_BANNERS_FAILURE',
}

export interface Banner {
    id?: string;
    path?: string;
}

export interface BannersState {
    data: Banner[];
    loading: boolean;
    error: boolean
}