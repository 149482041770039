export enum CategoriesTypes {
    'LOAD_CATEGORIES' = '@mioca/LOAD_CATEGORIES',
    'LOAD_CATEGORIES_SUCCESS' = '@mioca/LOAD_LOAD_CATEGORIES_SUCCESS',
    'LOAD_CATEGORIES_FAILURE' = '@mioca/LOAD_CATEGORIES_FAILURE',
	'LOAD_CATEGORY' = '@mioca/LOAD_CATEGORY',
    'LOAD_CATEGORY_SUCCESS' = '@mioca/LOAD_CATEGORY_SUCCESS',
    'LOAD_CATEGORY_FAILURE' = '@mioca/LOAD_CATEGORY_FAILURE',
	'SAVE_CATEGORY'= '@mioca/SAVE_CATEGORY',
	'SAVE_CATEGORY_SUCCESS'= '@mioca/SAVE_CATEGORY_SUCCESS',
	'SAVE_CATEGORY_FAILURE'= '@mioca/SAVE_CATEGORY_FAILURE',
	'DELETE_CATEGORY'= '@mioca/DELETE_CATEGORY',
	'DELETE_CATEGORY_SUCCESS'= '@mioca/DELETE_CATEGORY_SUCCESS',
	'DELETE_CATEGORY_FAILURE'= '@mioca/DELETE_CATEGORY_FAILURE',
	'INSERT_CATEGORY'= '@mioca/INSERT_CATEGORY',
	'INSERT_CATEGORY_SUCCESS'= '@mioca/INSERT_CATEGORY_SUCCESS',
	'INSERT_CATEGORY_FAILURE'= '@mioca/INSERT_CATEGORY_FAILURE'
}

export interface Category {
    id?: string;
    en_name?: string;
    pt_name?: string;
	color?: string;
    slug?: string;
}

export interface CategoriesState {
    data: Category[] | Category;
    loading: boolean;
    error: boolean
}