import {
  all,
  call,
  put,
  StrictEffect,
  take,
  takeLatest
} from 'redux-saga/effects';
import api from 'src/services/api';
import { LoginTypes } from './types';
import * as Actions from './actions';

export function* login() {
  console.log("setando para true");
  
  // yield put(Actions.login(true));
}

export function* logout() {
    // yield put(Actions.logout(false));
}

export default function* loginSagas(): Generator<StrictEffect> {
  yield all([
    takeLatest(LoginTypes.LOGIN, login),
    takeLatest(LoginTypes.LOGOUT, logout),
  ]);
}
