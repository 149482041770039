import { createStore, Store, applyMiddleware, compose } from 'redux';
import rootReducer, { ApplicationState } from './rootReducer';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  (window &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)._REDUX_DEVTOOLS_EXTENSION_COMPOSE_) ||
  compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));
const store: Store<ApplicationState> = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);
export default store;
