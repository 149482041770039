/* eslint default-param-last: ["error"] */
import { Reducer } from 'redux';
import { CategoriesState, CategoriesTypes } from './types';

const INITIAL_STATE: CategoriesState = {
  data: null,
  error: false,
  loading: false
};

function reducer(
  state = INITIAL_STATE,
  action: any = { type: '', payload: '' }
): CategoriesState {
  switch (action.type) {
    case CategoriesTypes.LOAD_CATEGORIES:
      return {
        ...state,
        loading: true
      };
    case CategoriesTypes.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case CategoriesTypes.LOAD_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case CategoriesTypes.LOAD_CATEGORY:
      return {
        ...state,
        loading: true
      };
    case CategoriesTypes.LOAD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case CategoriesTypes.LOAD_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case CategoriesTypes.DELETE_CATEGORY:
      return {
        ...state,
        loading: true
      };
    case CategoriesTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case CategoriesTypes.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
      case CategoriesTypes.INSERT_CATEGORY:
        return {
          ...state,
          loading: true
        };
      case CategoriesTypes.INSERT_CATEGORY_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: action.payload
        };
      case CategoriesTypes.INSERT_CATEGORY_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          data: null
        };
    default:
      return state;
  }
}

export default reducer;
