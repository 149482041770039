import { action } from 'typesafe-actions'
import { SoftwaresTypes } from './types'

// Softwares
export const loadSoftwares = () => action(SoftwaresTypes.LOAD_SOFTWARES);
export const loadSoftwaresSuccess = (payload: any) => action(SoftwaresTypes.LOAD_SOFTWARES_SUCCESS, payload);
export const loadSoftwaresFailure = () => action(SoftwaresTypes.LOAD_SOFTWARES_FAILURE);

export const loadSoftware = (payload: any) => action(SoftwaresTypes.LOAD_SOFTWARE, payload);
export const loadSoftwareSuccess = (payload: any) => action(SoftwaresTypes.LOAD_SOFTWARE_SUCCESS, payload);
export const loadSoftwareFailure = () => action(SoftwaresTypes.LOAD_SOFTWARE_FAILURE);

export const saveSoftware = (payload: any) => action(SoftwaresTypes.SAVE_SOFTWARE, payload);
export const saveSoftwareSuccess = (payload: any) => action(SoftwaresTypes.SAVE_SOFTWARE_SUCCESS, payload);
export const saveSoftwareFailure = () => action(SoftwaresTypes.SAVE_SOFTWARE_FAILURE);

export const deleteSoftware = (payload: any) => action(SoftwaresTypes.DELETE_SOFTWARE, payload);
export const deleteSoftwareSuccess = (payload: any) => action(SoftwaresTypes.DELETE_SOFTWARE_SUCCESS, payload);
export const deleteSoftwareFailure = () => action(SoftwaresTypes.DELETE_SOFTWARE_FAILURE);

export const insertSoftware = (payload: any) => action(SoftwaresTypes.INSERT_SOFTWARE, payload);
export const insertSoftwareSuccess = (payload: any) => action(SoftwaresTypes.INSERT_SOFTWARE_SUCCESS, payload);
export const insertSoftwareFailure = () => action(SoftwaresTypes.INSERT_SOFTWARE_FAILURE);