import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Button, Card, Container, Grid } from '@mui/material';

import Footer from 'src/components/Footer';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import { useEffect, useState } from 'react';
import { CategoriesTypes, Category } from 'src/store/categories/types';
import * as Actions from '../../store/categories/actions';
import Swal from 'sweetalert2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function Categories() {
  const dispatch = useDispatch();
  const categoriesData = useSelector(
    (state: ApplicationState) => state?.categories?.data
  ) as Category[];

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    dispatch({ type: CategoriesTypes.LOAD_CATEGORIES });
  }, []);

  useEffect(() => {
    if (categoriesData?.length > 0) {
      setRows(categoriesData);
    }
  }, [categoriesData]);

  const navigate = useNavigate();
  const handleEdit = (id) => navigate(`/category/${id}`);
  const handleInclude = () => navigate(`/category`);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja excluir o item?',
      showDenyButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonColor: 'primary',
      icon: 'question',
      denyButtonText: `Cancelar`,
      denyButtonColor: 'secondary'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteCategory({ id }));
        const filteredCategories = rows.filter((category) => {
          return category.id !== id;
        });
        setRows(filteredCategories);
        Swal.fire(
          'Excluido!',
          'O item foi excluido com sucesso',
          'success'
        );
      }
    });
  };

  const columns: GridColDef[] = [
    { field: 'pt_name', headerName: 'Nome (PT)', width: 220 },
    { field: 'en_name', headerName: 'Nome (EN)', width: 220 },
    { field: 'color', headerName: 'Cor', width: 150},
    { field: 'slug', headerName: 'Slug (URL)', width: 200 },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(event) => handleEdit(id)}
            color="inherit"
            key={`${id}-edit`}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(event) => handleDelete(id)}
            color="inherit"
            key={`${id}-delete`}
          />
        ];
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Categorias</title>
      </Helmet>
      <PageTitleWrapper>
        <div style={{display: 'flex'}}>
        <PageTitle
          heading="Listagem de Categorias"
          subHeading="As categorias são utilizadas para agrupar e definir tipos de projetos"
        />
        <Button
              variant="contained"
              sx={{width: '250px'}}
              onClick={handleInclude}
              endIcon={<AddCircleOutlineIcon />}
              color="success"
            >
              Novo item
            </Button>
        </div>

      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <div style={{ padding: '15px' }}>
                <DataGrid rows={rows} columns={columns} autoHeight />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Categories;
