/* eslint default-param-last: ["error"] */
import { Reducer } from 'redux';
import { ProjectsState, ProjectsTypes } from './types';

const INITIAL_STATE: ProjectsState = {
  data: null,
  error: false,
  loading: false
};

function reducer(
  state = INITIAL_STATE,
  action: any = { type: '', payload: '' }
): ProjectsState {
  switch (action.type) {
    case ProjectsTypes.LOAD_PROJECTS:
      return {
        ...state,
        loading: true
      };
    case ProjectsTypes.LOAD_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case ProjectsTypes.LOAD_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case ProjectsTypes.LOAD_PROJECT:
      return {
        ...state,
        loading: true
      };
    case ProjectsTypes.LOAD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case ProjectsTypes.LOAD_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case ProjectsTypes.DELETE_PROJECT:
      return {
        ...state,
        loading: true
      };
    case ProjectsTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case ProjectsTypes.DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case ProjectsTypes.INSERT_PROJECT:
      return {
        ...state,
        loading: true
      };
    case ProjectsTypes.INSERT_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case ProjectsTypes.INSERT_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
      case ProjectsTypes.REMOVE_IMAGE:
        return {
          ...state,
          loading: true
        };
      case ProjectsTypes.REMOVE_IMAGE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: action.payload
        };
      case ProjectsTypes.REMOVE_IMAGE_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          data: null
        };
    default:
      return state;
  }
}

export default reducer;
