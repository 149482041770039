import { action } from 'typesafe-actions'
import { ProjectsTypes } from './types'

// Projects
export const loadProjects = () => action(ProjectsTypes.LOAD_PROJECTS);
export const loadProjectsSuccess = (payload: any) => action(ProjectsTypes.LOAD_PROJECTS_SUCCESS, payload);
export const loadProjectsFailure = () => action(ProjectsTypes.LOAD_PROJECTS_FAILURE);

export const loadProject = (payload: any) => action(ProjectsTypes.LOAD_PROJECT, payload);
export const loadProjectSuccess = (payload: any) => action(ProjectsTypes.LOAD_PROJECT_SUCCESS, payload);
export const loadProjectFailure = () => action(ProjectsTypes.LOAD_PROJECT_FAILURE);

export const saveProject = (payload: any) => action(ProjectsTypes.SAVE_PROJECT, payload);
export const saveProjectSuccess = (payload: any) => action(ProjectsTypes.SAVE_PROJECT_SUCCESS, payload);
export const saveProjectFailure = () => action(ProjectsTypes.SAVE_PROJECT_FAILURE);

export const deleteProject = (payload: any) => action(ProjectsTypes.DELETE_PROJECT, payload);
export const deleteProjectSuccess = (payload: any) => action(ProjectsTypes.DELETE_PROJECT_SUCCESS, payload);
export const deleteProjectFailure = () => action(ProjectsTypes.DELETE_PROJECT_FAILURE);

export const insertProject = (payload: any) => action(ProjectsTypes.INSERT_PROJECT, payload);
export const insertProjectSuccess = (payload: any) => action(ProjectsTypes.INSERT_PROJECT_SUCCESS, payload);
export const insertProjectFailure = () => action(ProjectsTypes.INSERT_PROJECT_FAILURE);

export const removeImage = (payload: any) => action(ProjectsTypes.REMOVE_IMAGE, payload);
export const removeImageSuccess = (payload: any) => action(ProjectsTypes.REMOVE_IMAGE_SUCCESS, payload);
export const removeImageFailure = () => action(ProjectsTypes.REMOVE_IMAGE_FAILURE);