import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { ChangeEvent, useEffect, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  CardHeader
} from '@mui/material';
import Swal from 'sweetalert2';

import Footer from 'src/components/Footer';

import { ArrowForwardTwoTone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import * as Actions from '../../store/softwares/actions';
import { useParams } from 'react-router-dom';
import { Software as SoftwareType } from 'src/store/softwares/types';

function Software() {
  const dispatch = useDispatch();
  const softwares = useSelector(
    (state: ApplicationState) => state.softwares.data
  ) as SoftwareType[];
  const { id } = useParams();
  console.log("id", id);
  
  const [software, setSoftware] = useState<SoftwareType>();
  const [originalName, setOriginalName] = useState<string>('novo software*');
    const [image, setImage] = useState<File>()

  useEffect(() => {
    if (softwares?.length > 0) {
      const result = softwares.find((software) => {
        return software.id === id;
      });
      setOriginalName(result?.pt_name);
      setSoftware(result);
    }
  }, [softwares]);

  const handleUpdate = () => {
    if (software?.id) {
      dispatch(
        Actions.saveSoftware({
          ...software,
          software: image
        })
      );
    } else {
      dispatch(
        Actions.insertSoftware({
          ...software,
          software: image
        })
      );
    }
    Swal.fire({
      title: 'Sucesso!',
      text: 'Os dados foram atualizados com sucesso',
      icon: 'success',
      confirmButtonText: 'Feito'
    }).then(() => {
      history.back();
    });
  };

  const handleFileInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    setImage(file)
  };
  
  return (
    <>
      <Helmet>
        <title>Mioca Admin | Software</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Sofware"
          subHeading="Os softwares são utilizados para categorizar projetos"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <CardHeader title={`Sofware: ${originalName}`} />

                  <div>
                    <TextField
                      id="software-name-pt"
                      label="Nome (PT)"
                      type="text"
                      value={software?.pt_name || ''}
                      onChange={(event) =>
                        setSoftware({
                          ...software,
                          pt_name: event.target.value
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div>
                    <label
                      style={{
                        paddingLeft: '25px',
                        color: 'rgba(34, 51, 84, 0.7)'
                      }}
                      htmlFor="software-image"
                    >
                      Imagem
                    </label>
                  </div>
                  <div>
                    <TextField
                      id="software-image"
                      type="file"
                      onChange={handleFileInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleUpdate}
              endIcon={<ArrowForwardTwoTone />}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Software;
