import { all, call, put, StrictEffect, take, takeLatest } from 'redux-saga/effects'
import api from 'src/services/api'
import { CategoriesTypes } from './types';
import * as Actions from './actions'

export function* getCategories() {
    try {
        const response = yield call(api.get, '/categories')
        console.log("response | categories", response.data);
        
        yield put(Actions.loadCategoriesSuccess(response?.data))
    } catch (error) {
        yield put(Actions.loadCategoriesFailure())
    }
}

export function* getCategory(payload) {

    console.log("Payload get category", payload);
    
    try {
        const response = yield call(api.get, `/category/${payload.payload.id}`)
        console.log("response | category", response.data);
        
        yield put(Actions.loadCategoriesSuccess(response?.data))
    } catch (error) {
        yield put(Actions.loadCategoriesFailure())
    }
}

export function* saveCategory(payload: any) {
    try {
        const response = yield call(api.put, `/category/${payload?.payload?.id}`, payload.payload)
        console.log("RESPOSTA SAVE_CATEGORY", response.data);
        
        yield put(Actions.saveCategorySuccess(response.data))
    } catch (error) {
        yield put(Actions.saveCategoryFailure())
    }
}

export function* insertCategory(payload: any) {
    try {
        const response = yield call(api.post, `/category`, payload.payload)
        console.log("RESPOSTA INSERT_CATEGORY", response.data);
        
        yield put(Actions.insertCategorySuccess(response.data))
    } catch (error) {
        yield put(Actions.insertCategoryFailure())
    }
}

export function* deleteCategory(payload: any) {
    try {
        const response = yield call(api.delete, `/category/${payload?.payload?.id}`)
        console.log("RESPOSTA DELETE_CATEGORY", response.data);
        
        yield put(Actions.deleteCategorySuccess(response.data))
    } catch (error) {
        yield put(Actions.deleteCategoryFailure())
    }
}

export default function* categoriesSagas(): Generator<StrictEffect> {
    yield all([
        takeLatest(CategoriesTypes.LOAD_CATEGORIES, getCategories),
        takeLatest(CategoriesTypes.LOAD_CATEGORY, getCategory),
        takeLatest(CategoriesTypes.SAVE_CATEGORY, saveCategory),
        takeLatest(CategoriesTypes.DELETE_CATEGORY, deleteCategory),
        takeLatest(CategoriesTypes.INSERT_CATEGORY, insertCategory)
    ])
}