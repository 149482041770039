import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'
import api from 'src/services/api'
import { General, GeneralTypes } from './types';
import * as Actions from './actions'

export function* getGeneral() {
    try {
        const response = yield call(api.get, '/general')
        console.log("response | general", response.data);
        
        yield put(Actions.loadSuccess(response?.data))
    } catch (error) {
        yield put(Actions.loadFailure())
    }
}

export function* saveGeneral(payload: any) {

    console.log("payload", payload);
    const logoPT = payload.payload.pt.logo
    const logoEN = payload.payload.en.logo
    const aboutImagePT = payload.payload.pt.about_image
    const aboutImageEN = payload.payload.en.about_image
    const pagesBannerPT = payload.payload.pt.pages_banner
    const pagesBannerEN = payload.payload.en.pages_banner

    const body = {
        ...payload.payload,
    }

    body.pt.logo = undefined
    body.pt.about_image = undefined
    body.pt.pages_banner = undefined
    body.en.logo = undefined
    body.en.about_image = undefined
    body.en.pages_banner = undefined
    
    try {
        const response = yield call(api.post, '/general', {
            body: JSON.stringify(body), 
            logoPT,
            logoEN,
            aboutImagePT,
            aboutImageEN,
            pagesBannerPT,
            pagesBannerEN
        }, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        console.log("RESPOSTA SAVE_GENERAL", response.data);
        
        yield put(Actions.saveGeneralSuccess(response.data))
    } catch (error) {
        yield put(Actions.saveGeneralFailure())
    }
}

export default function* generalSagas(): Generator<StrictEffect> {
    yield all([
        takeLatest(GeneralTypes.LOAD_GENERAL, getGeneral),
        takeLatest(GeneralTypes.SAVE_GENERAL, saveGeneral)
    ])
}