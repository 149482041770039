import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { ChangeEvent, useEffect, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Tab,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider
} from '@mui/material';
import Swal from 'sweetalert2';

import Footer from 'src/components/Footer';

import { ArrowForwardTwoTone } from '@mui/icons-material';
import { GeneralDetail, GeneralTypes } from 'src/store/general/types';
import { useDispatch } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import * as GeneralActions from '../../store/general/actions';

function General() {
  const dispatch = useDispatch();
  const generalData = useSelector(
    (state: ApplicationState) => state.general.data
  );
  const [ptData, setPTData] = useState<GeneralDetail>();
  const [enData, setENData] = useState<GeneralDetail>();

  useEffect(() => {
    if (generalData?.pt && generalData?.en) {
      setPTData(generalData.pt);
      setENData(generalData.en);
    }
  }, [generalData]);

  useEffect(() => {
    dispatch({ type: GeneralTypes.LOAD_GENERAL });
  }, []);

  const [activeTab, setActiveTab] = useState('pt');

  const updateGeneral = () => {
    dispatch(
      GeneralActions.saveGeneral({
        en: enData,
        pt: ptData
      })
    );

    Swal.fire({
      title: 'Mal feito, feito!',
      text: 'Os dados foram atualizados com sucesso',
      icon: 'success',
      confirmButtonText: 'Feito'
    });
  };

  const handleFileInput = (event: ChangeEvent, type: string) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    switch (type) {
      case 'logoPT':
        setPTData({ ...ptData, logo: file });
        break;
      case 'logoEN':
        setENData({ ...enData, logo: file });
        break;
      case 'aboutPT':
        setPTData({ ...ptData, about_image: file });
        break;
      case 'aboutEN':
        setENData({ ...enData, about_image: file });
        break;
      case 'bannerPT':
        setPTData({ ...ptData, pages_banner: file });
        break;
      case 'bannerEN':
        setENData({ ...enData, pages_banner: file });
        break;
      default:
        break;
    }
  };

  const handleChange = (event: React.SyntheticEvent, tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Configurações Gerais</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Configurações Gerais"
          subHeading="As configurações gerais do site como logo, nome, linkedin e email são configuradas em inglês e português aqui"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={activeTab}>
                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                      <TabList onChange={handleChange} aria-label="Linguaguem">
                        <Tab label="Português" value="pt" />
                        <Tab label="Inglês" value="en" />
                      </TabList>
                    </Box>

                    <TabPanel value="pt">
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '98%' }
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <CardHeader title="Sobre mim" />

                        <div>
                          <TextField
                            id="about-name-pt"
                            label="Nome"
                            type="text"
                            value={ptData?.about_name || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                about_name: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="about-location-pt"
                            label="Localização"
                            type="text"
                            value={ptData?.about_location || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                about_location: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="about-description-pt"
                            label="Descrição"
                            type="text"
                            multiline
                            rows={10}
                            value={ptData?.about_description || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                about_description: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="about-photo-pt"
                          >
                            Foto
                          </label>
                        </div>
                        <div>
                          <TextField
                            id="about-photo-pt"
                            type="file"
                            onChange={(event) =>
                              handleFileInput(event, 'aboutPT')
                            }
                          />
                        </div>
                        <Divider
                          sx={{ marginBottom: '10px', marginTop: '25px' }}
                        />
                        <CardHeader title="Redes sociais" />
                        <div>
                          <TextField
                            id="social-instagram-pt"
                            label="Instagram"
                            type="text"
                            value={ptData?.instagram || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                instagram: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-twitter-pt"
                            label="Twitter"
                            type="text"
                            value={ptData?.twitter || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                twitter: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-linkedin-pt"
                            label="Linkedin"
                            type="text"
                            value={ptData?.linkedin || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                linkedin: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-vimeo-pt"
                            label="Vimeo"
                            type="text"
                            value={ptData?.vimeo || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                vimeo: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-behance-pt"
                            label="Behance"
                            type="text"
                            value={ptData?.behance || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                behance: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-artstation-pt"
                            label="Artstation"
                            type="text"
                            value={ptData?.artstation || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                artstation: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-whatsapp-pt"
                            label="Whatsapp"
                            type="text"
                            value={ptData?.whatsapp || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                whatsapp: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-telegram-pt"
                            label="Telegram"
                            type="text"
                            value={ptData?.telegram || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                telegram: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-discord-pt"
                            label="Discord"
                            type="text"
                            value={ptData?.discord || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                discord: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <Divider
                          sx={{ marginBottom: '10px', marginTop: '25px' }}
                        />
                        <CardHeader title="Reels" />
                        <div>
                          <TextField
                            id="reels-video-pt"
                            label="Video"
                            type="text"
                            value={ptData?.reels_video || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                reels_video: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="reels-description-pt"
                            label="Descrição"
                            type="text"
                            multiline
                            rows={10}
                            value={ptData?.reels_description || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                reels_description: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <Divider
                          sx={{ marginBottom: '10px', marginTop: '25px' }}
                        />
                        <CardHeader title="Outros" />
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="logo-pt"
                          >
                            Logo
                          </label>
                        </div>
                        <div>
                          <TextField
                            id="logo-pt"
                            type="file"
                            onChange={(event) =>
                              handleFileInput(event, 'logoPT')
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="page-banner-pt"
                          >
                            Banner páginas
                          </label>
                        </div>
                        <div>
                          <TextField
                            id="page-banner-pt"
                            type="file"
                            onChange={(event) =>
                              handleFileInput(event, 'bannerPT')
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            id="developer-pt"
                            label="Desenvolvedor"
                            type="text"
                            value={ptData?.developer || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                developer: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="email-pt"
                            label="E-mail"
                            type="text"
                            value={ptData?.email || ''}
                            onChange={(event) =>
                              setPTData({
                                ...ptData,
                                email: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </Box>
                    </TabPanel>
                    <TabPanel value="en">
                      <Box
                        component="form"
                        sx={{
                          '& .MuiTextField-root': { m: 1, width: '98%' }
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <CardHeader title="Sobre mim" />

                        <div>
                          <TextField
                            id="about-name-en"
                            label="Nome"
                            type="text"
                            value={enData?.about_name || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                about_name: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="about-location-en"
                            label="Localização"
                            type="text"
                            value={enData?.about_location || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                about_location: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="about-description-en"
                            label="Descrição"
                            type="text"
                            multiline
                            rows={10}
                            value={enData?.about_description || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                about_description: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="about-photo-en"
                          >
                            Foto
                          </label>
                        </div>
                        <div>
                          <TextField
                            id="about-photo-en"
                            type="file"
                            onChange={(event) =>
                              handleFileInput(event, 'aboutEN')
                            }
                          />
                        </div>
                        <Divider
                          sx={{ marginBottom: '10px', marginTop: '25px' }}
                        />
                        <CardHeader title="Redes sociais" />
                        <div>
                          <TextField
                            id="social-instagram-en"
                            label="Instagram"
                            type="text"
                            value={enData?.instagram || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                instagram: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-twitter-en"
                            label="Twitter"
                            type="text"
                            value={enData?.twitter || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                twitter: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-linkedin-en"
                            label="Linkedin"
                            type="text"
                            value={enData?.linkedin || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                linkedin: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-vimeo-en"
                            label="Vimeo"
                            type="text"
                            value={enData?.vimeo || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                vimeo: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-behance-en"
                            label="Behance"
                            type="text"
                            value={enData?.behance || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                behance: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-artstation-en"
                            label="Artstation"
                            type="text"
                            value={enData?.artstation || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                artstation: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-whatsapp-en"
                            label="Whatsapp"
                            type="text"
                            value={enData?.whatsapp || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                whatsapp: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-telegram-en"
                            label="Telegram"
                            type="text"
                            value={enData?.telegram || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                telegram: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                          <TextField
                            id="social-discord-en"
                            label="Discord"
                            type="text"
                            value={enData?.discord || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                discord: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <Divider
                          sx={{ marginBottom: '10px', marginTop: '25px' }}
                        />
                        <CardHeader title="Reels" />
                        <div>
                          <TextField
                            id="reels-video-en"
                            label="Video"
                            type="text"
                            value={enData?.reels_video || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                reels_video: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="reels-description-en"
                            label="Descrição"
                            type="text"
                            multiline
                            rows={10}
                            value={enData?.reels_description || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                reels_description: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <Divider
                          sx={{ marginBottom: '10px', marginTop: '25px' }}
                        />
                        <CardHeader title="Outros" />
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="logo-en"
                          >
                            Logo
                          </label>
                        </div>
                        <div>
                          <TextField
                            id="logo-en"
                            type="file"
                            onChange={(event) =>
                              handleFileInput(event, 'logoEN')
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="page-banner-en"
                          >
                            Banner páginas
                          </label>
                        </div>
                        <div>
                          <TextField
                            id="page-banner-en"
                            type="file"
                            onChange={(event) =>
                              handleFileInput(event, 'bannerEN')
                            }
                          />
                        </div>
                        <div>
                          <TextField
                            id="developer-en"
                            label="Desenvolvedor"
                            type="text"
                            value={enData?.developer || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                developer: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="email-en"
                            label="E-mail"
                            type="text"
                            value={enData?.email || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                email: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                      </Box>
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={updateGeneral}
              endIcon={<ArrowForwardTwoTone />}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default General;
