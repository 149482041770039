export enum GeneralTypes {
    'LOAD_GENERAL' = '@mioca/LOAD_GENERAL',
    'LOAD_SUCCESS' = '@mioca/LOAD_SUCCESS',
    'LOAD_FAILURE' = '@mioca/LOAD_FAILURE',
	'SAVE_GENERAL'= '@mioca/SAVE_GENERAL',
	'SAVE_GENERAL_SUCCESS'= '@mioca/SAVE_GENERAL_SUCCESS',
	'SAVE_GENERAL_FAILURE'= '@mioca/SAVE_GENERAL_FAILURE'
}

export interface GeneralDetail {
	about_name?: string;
	about_description?: string;
	about_location?: string;
	about_image?: string|File;
	reels_video?: string;
	reels_description?: string;
	logo?: string|File;
	email?: string;
	developer?: string;
	instagram?: string;
	twitter?: string;
	linkedin?: string;
	vimeo?: string;
	behance?: string;
	artstation?: string;
	whatsapp?: string;
	telegram?: string;
	discord?: string;
	pages_banner?: string|File;
}

export interface General {
    id?: string;
    pt?: GeneralDetail;
    en?: GeneralDetail;
}

export interface GeneralState {
    data: General;
    loading: boolean;
    error: boolean
}