import {
  all,
  call,
  put,
  StrictEffect,
  take,
  takeLatest
} from 'redux-saga/effects';
import api from 'src/services/api';
import { ProjectsTypes } from './types';
import * as Actions from './actions';

export function* getProjects() {
  try {
    const response = yield call(api.get, '/projects');
    console.log('response | projects', response.data);

    yield put(Actions.loadProjectsSuccess(response?.data));
  } catch (error) {
    yield put(Actions.loadProjectsFailure());
  }
}

export function* getProject(payload) {
  console.log('Payload get project', payload);

  try {
    const response = yield call(api.get, `/project/${payload.payload.id}`);
    console.log('response | project', response.data);

    yield put(Actions.loadProjectSuccess(response?.data));
  } catch (error) {
    yield put(Actions.loadProjectFailure());
  }
}

export function* saveProject(payload: any) {

  const formData = new FormData();
  if(payload.payload?.newImages){
    for (const image of payload.payload?.newImages) {
      formData.append('images', image)
    }
  }
  formData.append('body', JSON.stringify(payload.payload?.body))

  if(payload?.payload?.thumbnail){
    formData.append('thumbnail', payload?.payload?.thumbnail)
  }

  try {
    const response = yield call(
      api.put,
      `/project/${payload?.payload?.body?.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    console.log('RESPOSTA SAVE_PROJECT', response.data);

    yield put(Actions.saveProjectSuccess(response.data));
  } catch (error) {
    yield put(Actions.saveProjectFailure());
  }
}

export function* insertProject(payload: any) {

  const formData = new FormData();
  if(payload.payload?.newImages){
    for (const image of payload.payload?.newImages) {
      formData.append('images', image)
    }
  }
  formData.append('body', JSON.stringify(payload.payload?.body))

  if(payload?.payload?.thumbnail){
    formData.append('thumbnail', payload?.payload?.thumbnail)
  }

  try {
    const response = yield call(
      api.post,
      `/project`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    console.log('RESPOSTA INSERT_PROJECT', response.data);

    yield put(Actions.insertProjectSuccess(response.data));
  } catch (error) {
    yield put(Actions.insertProjectFailure());
  }
}

export function* deleteProject(payload: any) {
  try {
    const response = yield call(api.delete, `/project/${payload?.payload?.id}`);
    console.log('RESPOSTA DELETE_PROJECT', response.data);

    yield put(Actions.deleteProjectSuccess(response.data));
  } catch (error) {
    yield put(Actions.deleteProjectFailure());
  }
}

export function* removeImage(payload: any) {
  try {
    const response = yield call(
      api.delete,
      `/project/${payload?.payload?.id}/remove-image/${payload?.payload?.imageID}`
    );
    console.log('RESPOSTA REMOVE_IMAGE', response.data);

    yield put(Actions.removeImageSuccess(response.data));
  } catch (error) {
    yield put(Actions.removeImageFailure());
  }
}

export default function* projectsSagas(): Generator<StrictEffect> {
  yield all([
    takeLatest(ProjectsTypes.LOAD_PROJECTS, getProjects),
    takeLatest(ProjectsTypes.LOAD_PROJECT, getProject),
    takeLatest(ProjectsTypes.SAVE_PROJECT, saveProject),
    takeLatest(ProjectsTypes.DELETE_PROJECT, deleteProject),
    takeLatest(ProjectsTypes.INSERT_PROJECT, insertProject),
    takeLatest(ProjectsTypes.REMOVE_IMAGE, removeImage)
  ]);
}
