export enum SoftwaresTypes {
    'LOAD_SOFTWARES' = '@mioca/LOAD_SOFTWARES',
    'LOAD_SOFTWARES_SUCCESS' = '@mioca/LOAD_SOFTWARES_SUCCESS',
    'LOAD_SOFTWARES_FAILURE' = '@mioca/LOAD_SOFTWARES_FAILURE',

	'LOAD_SOFTWARE' = '@mioca/LOAD_SOFTWARE',
    'LOAD_SOFTWARE_SUCCESS' = '@mioca/LOAD_SOFTWARE_SUCCESS',
    'LOAD_SOFTWARE_FAILURE' = '@mioca/LOAD_SOFTWARE_FAILURE',

	'SAVE_SOFTWARE'= '@mioca/SAVE_SOFTWARE',
	'SAVE_SOFTWARE_SUCCESS'= '@mioca/SAVE_SOFTWARE_SUCCESS',
	'SAVE_SOFTWARE_FAILURE'= '@mioca/SAVE_SOFTWARE_FAILURE',

	'DELETE_SOFTWARE'= '@mioca/DELETE_SOFTWARE',
	'DELETE_SOFTWARE_SUCCESS'= '@mioca/DELETE_SOFTWARE_SUCCESS',
	'DELETE_SOFTWARE_FAILURE'= '@mioca/DELETE_SOFTWARE_FAILURE',

	'INSERT_SOFTWARE'= '@mioca/INSERT_SOFTWARE',
	'INSERT_SOFTWARE_SUCCESS'= '@mioca/INSERT_SOFTWARE_SUCCESS',
	'INSERT_SOFTWARE_FAILURE'= '@mioca/INSERT_SOFTWARE_FAILURE'
}

export interface Software {
    id?: string;
    path?: string;
    pt_name?: string;
}

export interface SoftwaresState {
    data: Software[] | Software;
    loading: boolean;
    error: boolean
}