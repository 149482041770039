/* eslint default-param-last: ["error"] */
import { Reducer } from 'redux';
import { SoftwaresState, SoftwaresTypes } from './types';

const INITIAL_STATE: SoftwaresState = {
  data: null,
  error: false,
  loading: false
};

function reducer(
  state = INITIAL_STATE,
  action: any = { type: '', payload: '' }
): SoftwaresState {
  switch (action.type) {
    case SoftwaresTypes.LOAD_SOFTWARES:
      return {
        ...state,
        loading: true
      };
    case SoftwaresTypes.LOAD_SOFTWARES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case SoftwaresTypes.LOAD_SOFTWARES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case SoftwaresTypes.LOAD_SOFTWARE:
      return {
        ...state,
        loading: true
      };
    case SoftwaresTypes.LOAD_SOFTWARE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case SoftwaresTypes.LOAD_SOFTWARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case SoftwaresTypes.DELETE_SOFTWARE:
      return {
        ...state,
        loading: true
      };
    case SoftwaresTypes.DELETE_SOFTWARE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case SoftwaresTypes.DELETE_SOFTWARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case SoftwaresTypes.INSERT_SOFTWARE:
      return {
        ...state,
        loading: true
      };
    case SoftwaresTypes.INSERT_SOFTWARE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case SoftwaresTypes.INSERT_SOFTWARE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    default:
      return state;
  }
}

export default reducer;
