import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';
import { ApplicationState } from 'src/store/rootReducer';

const ProtectedRoutes = () => {
  const logged = useSelector(
    (state: ApplicationState) => state?.login?.loggedIn
  );

  const useAuth = () => {
    const user = { loggedIn: logged };
    return user && user.loggedIn;
  };

  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
