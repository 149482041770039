/* eslint default-param-last: ["error"] */
import { Reducer } from 'redux';
import { BannersState, BannersTypes } from './types';

const INITIAL_STATE: BannersState = {
  data: null,
  error: false,
  loading: false
};

function reducer(
  state = INITIAL_STATE,
  action: any = { type: '', payload: '' }
): BannersState {
  switch (action.type) {
    case BannersTypes.LOAD_BANNERS:
      return {
        ...state,
        loading: true
      };
    case BannersTypes.LOAD_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case BannersTypes.LOAD_BANNERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
      case BannersTypes.DELETE_BANNERS:
        return {
          ...state,
          loading: true
        };
      case BannersTypes.DELETE_BANNERS_SUCCESS:
        return {
          ...state,
          loading: false,
          error: false,
          data: action.payload
        };
      case BannersTypes.DELETE_BANNERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          data: null
        };
        case BannersTypes.SAVE_BANNERS:
          return {
            ...state,
            loading: true
          };
        case BannersTypes.SAVE_BANNERS_SUCCESS:
          return {
            ...state,
            loading: false,
            error: false,
            data: action.payload
          };
        case BannersTypes.SAVE_BANNERS_FAILURE:
          return {
            ...state,
            loading: false,
            error: true,
            data: null
          };
    default:
      return state;
  }
}

export default reducer;
