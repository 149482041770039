import { action } from 'typesafe-actions'
import {BannersTypes} from './types'

// Banners
export const loadBanners = () => action(BannersTypes.LOAD_BANNERS);
export const loadBannersSuccess = (payload: any) => action(BannersTypes.LOAD_BANNERS_SUCCESS, payload);
export const loadBannersFailure = () => action(BannersTypes.LOAD_BANNERS_FAILURE);

export const deleteBanner = (payload) => action(BannersTypes.DELETE_BANNERS, payload);
export const deleteBannerSuccess = (payload: any) => action(BannersTypes.DELETE_BANNERS_SUCCESS, payload);
export const deleteBannerFailure = () => action(BannersTypes.DELETE_BANNERS_FAILURE);

export const saveBanner = (payload) => action(BannersTypes.SAVE_BANNERS, payload);
export const saveBannerSuccess = (payload: any) => action(BannersTypes.SAVE_BANNERS_SUCCESS, payload);
export const saveBannerFailure = () => action(BannersTypes.SAVE_BANNERS_FAILURE);