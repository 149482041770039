import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { ChangeEvent, useEffect, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Tab,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  CardHeader
} from '@mui/material';
import Swal from 'sweetalert2';

import Footer from 'src/components/Footer';

import { ArrowForwardTwoTone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import { useParams } from 'react-router-dom';
import { Category as CategoryType } from 'src/store/categories/types';
import * as Actions from '../../store/banners/actions';

function Banner() {

  const dispatch = useDispatch();

  const [bannerFile, setBannerFile] = useState<File>()

  const handleUpdate = () => {

    console.log("bannerFile", bannerFile);
    
    dispatch(
      Actions.saveBanner({
        banner: bannerFile
      })
    );

    Swal.fire({
      title: 'Sucesso!',
      text: 'Os dados foram atualizados com sucesso',
      icon: 'success',
      confirmButtonText: 'Feito'
    }).then( () => {
      history.back()
    });
  };


  const handleFileInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    console.log("file", file);
    
    setBannerFile(file)
  };
  
  return (
    <>
      <Helmet>
        <title>Mioca Admin | Banner</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Banner"
          subHeading="Inserir novo banner para tela inicial"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <CardHeader title={`Banner`}/>

                  <div>
                    <TextField
                      id="banner"
                      type="file"
                      onChange={handleFileInput}
                    />
                  </div>
    
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleUpdate}
              endIcon={<ArrowForwardTwoTone />}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Banner;
