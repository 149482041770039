import { all, call, put, StrictEffect, takeLatest } from 'redux-saga/effects'
import api from 'src/services/api'
import { Banner, BannersTypes } from './types';
import * as Actions from './actions'

export function* getBanners() {
    try {
        const response = yield call(api.get, '/banners')
        console.log("response | banners", response.data);
        
        yield put(Actions.loadBannersSuccess(response?.data))
    } catch (error) {
        yield put(Actions.loadBannersFailure())
    }
}

export function* deleteBanner(payload) {
    try {
        const response = yield call(api.delete, `/banner/${payload?.payload?.id}`)
        console.log("response | banners", response.data);
        
        yield put(Actions.deleteBannerSuccess(response?.data))
    } catch (error) {
        yield put(Actions.deleteBannerFailure())
    }
}

export function* saveBanner(payload: any) {

    console.log(payload);
    
    try {
        const response = yield call(api.post, '/banner', {
            banner: payload.payload.banner
        }, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        console.log("RESPOSTA SAVE_BANNER", response.data);
        
        yield put(Actions.saveBannerSuccess(response.data))
    } catch (error) {
        yield put(Actions.saveBannerFailure())
    }
}

export default function* bannersSagas(): Generator<StrictEffect> {
    yield all([
        takeLatest(BannersTypes.LOAD_BANNERS, getBanners),
        takeLatest(BannersTypes.DELETE_BANNERS, deleteBanner),
        takeLatest(BannersTypes.SAVE_BANNERS, saveBanner)
    ])
}