import { Box, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';

const OverviewWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const Text = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(25)};
        font-weight: ${theme.typography.fontWeightBold};
`
);

const SubText = styled(Box)(
  ({ theme }) => `
        font-size: ${theme.typography.pxToRem(25)};
        font-weight: ${theme.typography.fontWeightLight};
`
);

function Home() {
  return (
    <OverviewWrapper>
      <Helmet>
        <title>Mioca Studios</title>
      </Helmet>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" py={5} alignItems="center" flexDirection="column" paddingTop="150px">
          <Text>Painel Adminstrativo</Text>
          <SubText>MIOCA STUDIOS</SubText>

          <img src="/static/images/mioca.gif" alt="" style={{width: '50vh', height: '100%'}} />
        </Box>
      </Container>
    </OverviewWrapper>
  );
}

export default Home;
