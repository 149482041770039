import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Home from './content/home';
import General from './content/general';
import Categories from './content/categories';
import Category from './content/categories/category';
import Banners from './content/banners';
import Banner from './content/banners/banner';
import Softwares from './content/softwares';
import Software from './content/softwares/software';
import Projects from './content/projects';
import Project from './content/projects/project';
import Login from './content/login';
import ProtectedRoutes from './content/login/protectedRoutes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const routes: RouteObject[] = [
  {
    path: 'login',
    element: <Login />
  },
  {
    path: '',
    element: <ProtectedRoutes />,
    children: [
      {
        path: '',
        element: <SidebarLayout />,
        children: [
          {
            path: '',
            element: <Home/>
          },
          {
            path: 'general',
            element: <General />
          },
          {
            path: 'categories',
            element: <Categories />
          },
          {
            path: 'category/:id',
            element: <Category />
          },
          {
            path: 'category',
            element: <Category />
          },
          {
            path: 'banners',
            element: <Banners />
          },
          {
            path: 'banner',
            element: <Banner />
          },
          {
            path: 'softwares',
            element: <Softwares />
          },
          {
            path: 'software/:id',
            element: <Software />
          },
          {
            path: 'software',
            element: <Software />
          },
          {
            path: 'projects',
            element: <Projects />
          },
          {
            path: 'project/:id',
            element: <Project />
          },
          {
            path: 'project',
            element: <Project />
          }
        ]
      }
    ]
  },

];

export default routes;
