import {combineReducers} from 'redux'
import { GeneralState } from './general/types';
import general from './general/index'
import categories from './categories/index'
import banners from './banners/index'
import softwares from './softwares/index'
import projects from './projects/index'
import login from './login/index'
import { CategoriesState } from './categories/types';
import { BannersState } from './banners/types';
import { SoftwaresState } from './softwares/types';
import { ProjectsState } from './projects/types';
import { LoginState } from './login/types';

export interface ApplicationState {
    applicationState: any;
    general: GeneralState;
    categories: CategoriesState,
    banners: BannersState,
    softwares: SoftwaresState,
    projects: ProjectsState,
    login: LoginState
}

export default combineReducers({
    general,
    categories,
    banners,
    softwares,
    projects,
    login
})