import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Button, Card, Container, Grid } from '@mui/material';

import Footer from 'src/components/Footer';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import { useEffect, useState } from 'react';
import { CategoriesTypes, Category } from 'src/store/categories/types';
import * as Actions from '../../store/projects/actions';
import Swal from 'sweetalert2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Project, ProjectsTypes } from 'src/store/projects/types';

function Projects() {
  const dispatch = useDispatch();
  const projectsData = useSelector(
    (state: ApplicationState) => state?.projects?.data
  ) as Project[];

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    dispatch({ type: ProjectsTypes.LOAD_PROJECTS });
  }, []);

  useEffect(() => {
    if (projectsData?.length > 0) {
      setRows(projectsData);
    }
  }, [projectsData]);

  const navigate = useNavigate();
  const handleEdit = (id) => navigate(`/project/${id}`);
  const handleInclude = () => navigate(`/project`);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja excluir o item?',
      showDenyButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonColor: 'primary',
      icon: 'question',
      denyButtonText: `Cancelar`,
      denyButtonColor: 'secondary'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteProject({ id }));
        const filteredProjects = rows.filter((project) => {
          return project.id !== id;
        });
        setRows(filteredProjects);
        Swal.fire('Excluido!', 'O item foi excluido com sucesso', 'success');
      }
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'name_pt',
      headerName: 'Nome (PT)',
      width: 250,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.pt.name}</div>;
      }
    },
    {
      field: 'name_en',
      headerName: 'Nome (EN)',
      width: 250,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.en.name}</div>;
      }
    },
    {
      field: 'categories',
      headerName: 'Categorias',
      width: 250,
      renderCell: (params) => {
        return (
          <div className="rowitem">
            {params.row.categories.map((category, index) => (
              <div key={index}>{"• " + category.pt_name}</div>
            ))}
          </div>
        );
      }
    },
    {
      field: 'home',
      headerName: 'Destaque?',
      align: 'center',
      width: 100,
      renderCell: (params) => {
        return <div className="rowitem">{params.row.home ? '🟢' : '🔴'}</div>;
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      align: 'right',
      headerAlign: 'right',
      flex: 1,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(event) => handleEdit(id)}
            color="inherit"
            key={`${id}-edit`}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(event) => handleDelete(id)}
            color="inherit"
            key={`${id}-delete`}
          />
        ];
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Projetos</title>
      </Helmet>
      <PageTitleWrapper>
        <div style={{ display: 'flex' }}>
          <PageTitle
            heading="Listagem de Projetos"
            subHeading="Os projetos possuem suporte a inglês e português, sendo as imagens compartilhadas para ambos idiomas"
          />
          <Button
            variant="contained"
            sx={{ width: '250px' }}
            onClick={handleInclude}
            endIcon={<AddCircleOutlineIcon />}
            color="success"
          >
            Novo item
          </Button>
        </div>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <div style={{ padding: '15px' }}>
                <DataGrid rows={rows} columns={columns} autoHeight />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Projects;
