export enum ProjectsTypes {
  'LOAD_PROJECTS' = '@mioca/LOAD_PROJECTS',
  'LOAD_PROJECTS_SUCCESS' = '@mioca/LOAD_PROJECTS_SUCCESS',
  'LOAD_PROJECTS_FAILURE' = '@mioca/LOAD_PROJECTS_FAILURE',

  'LOAD_PROJECT' = '@mioca/LOAD_PROJECT',
  'LOAD_PROJECT_SUCCESS' = '@mioca/LOAD_PROJECT_SUCCESS',
  'LOAD_PROJECT_FAILURE' = '@mioca/LOAD_PROJECT_FAILURE',

  'SAVE_PROJECT' = '@mioca/SAVE_PROJECT',
  'SAVE_PROJECT_SUCCESS' = '@mioca/SAVE_PROJECT_SUCCESS',
  'SAVE_PROJECT_FAILURE' = '@mioca/SAVE_PROJECT_FAILURE',

  'DELETE_PROJECT' = '@mioca/DELETE_PROJECT',
  'DELETE_PROJECT_SUCCESS' = '@mioca/DELETE_PROJECT_SUCCESS',
  'DELETE_PROJECT_FAILURE' = '@mioca/DELETE_PROJECT_FAILURE',

  'INSERT_PROJECT' = '@mioca/INSERT_PROJECT',
  'INSERT_PROJECT_SUCCESS' = '@mioca/INSERT_PROJECT_SUCCESS',
  'INSERT_PROJECT_FAILURE' = '@mioca/INSERT_PROJECT_FAILURE',

  'REMOVE_IMAGE' = '@mioca/REMOVE_IMAGE',
  'REMOVE_IMAGE_SUCCESS' = '@mioca/REMOVE_IMAGE_SUCCESS',
  'REMOVE_IMAGE_FAILURE' = '@mioca/REMOVE_IMAGE_FAILURE'
}

export interface ProjectDetail {
  credits: string;
  name: string;
  description: string;
  vimeo: string;
}

interface ProjectImage {
  id?: string;
  path?: string;
  columns?: string;
  height?: string;
  position?: number;
}

interface ProjectCategory {
  id?: string;
  en_name?: string;
  pt_name?: string;
}

interface ProjectSoftware {
  id?: string;
  pt_name?: string;
  path?: string;
}

export interface Project {
  id?: string;
  en?: ProjectDetail;
  pt?: ProjectDetail;
  images?: ProjectImage[];
  categories: ProjectCategory[];
  softwares: ProjectSoftware[];
  thumbnail?: string;
  home?: boolean;
}

export interface ProjectsState {
  data: Project[] | Project;
  loading: boolean;
  error: boolean;
}
