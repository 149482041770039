import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Button, Card, Container, Grid } from '@mui/material';

import Footer from 'src/components/Footer';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import { useEffect, useState } from 'react';
import { CategoriesTypes, Category } from 'src/store/categories/types';
import * as Actions from '../../store/banners/actions';
import Swal from 'sweetalert2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Banner, BannersTypes } from 'src/store/banners/types';

function Banners() {
  const dispatch = useDispatch();
  const bannersData = useSelector(
    (state: ApplicationState) => state?.banners?.data
  ) as Banner[];

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    dispatch({ type: BannersTypes.LOAD_BANNERS });
  }, []);

  useEffect(() => {
    if (bannersData?.length > 0) {
      setRows(bannersData);
    }
  }, [bannersData]);

  const navigate = useNavigate();
  const handleInclude = () => navigate(`/banner`);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja excluir o item?',
      showDenyButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonColor: 'primary',
      icon: 'question',
      denyButtonText: `Cancelar`,
      denyButtonColor: 'secondary'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteBanner({ id }));
        const filteredBanners = rows.filter((banner) => {
          return banner.id !== id;
        });
        setRows(filteredBanners);
        Swal.fire('Excluido!', 'O item foi excluido com sucesso', 'success');
      }
    });
  };

  const columns: GridColDef[] = [
    { field: 'path', headerName: 'Nome arquivo', width: 300 },
    {
      field: 'image',
      headerName: 'Arquivo',
      width: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <img src={`http://api.cayosparapani.com/images/${params.row.path}`} height="300px" style={{objectFit: "cover", width: "80vh"}} alt="" />
          </div>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      align: 'center',
      headerAlign: 'center',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(event) => handleDelete(id)}
            color="inherit"
            key={`${id}-delete`}
          />
        ];
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Banners</title>
      </Helmet>
      <PageTitleWrapper>
        <div style={{ display: 'flex' }}>
          <PageTitle
            heading="Listagem de Banners"
            subHeading="Os banners são exibidos na página inicial"
          />
          <Button
            variant="contained"
            sx={{ width: '250px' }}
            onClick={handleInclude}
            endIcon={<AddCircleOutlineIcon />}
            color="success"
          >
            Novo item
          </Button>
        </div>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <div style={{ padding: '15px' }}>
                <DataGrid rows={rows} columns={columns} rowHeight={250} autoHeight/>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Banners;
