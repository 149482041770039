import { TextField } from '@mui/material';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Actions from '../../store/login/actions';
import Swal from 'sweetalert2';
import './login.css';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user, setUser] = useState<string>();
  const [password, setPassword] = useState<string>();

  const handleLogin = () => {
    if (!user || !password) {
      Swal.fire({
        title: 'Campos obrigatórios',
        text: 'Preencha usuário e senha antes de continuar',
        icon: 'error',
        confirmButtonText: 'Fechar'
      });
    } else {
      if (user === 'cayo.mioca' && password === '!mioca@2023') {
        dispatch(Actions.login(true));
        navigate(`/`);
      } else {
        Swal.fire({
          title: 'Erro de autenticação',
          text: 'Usuário e/ou senha incorretos',
          icon: 'error',
          confirmButtonText: 'Fechar'
        });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Login</title>
      </Helmet>
      <div className="login-background">
        <div className="login-welcome">
          <img src="/static/images/logo/logo.png" alt="" />
          <h2>Mioca Studios</h2>
          <h1>Acesso Adminstrativo</h1>
        </div>
        <div className="login-panel">
          <input
            type="text"
            name="login"
            id="login"
            placeholder="usuário"
            onChange={(event) => setUser(event.target.value)}
          />
          <input
            type="password"
            name="password"
            id="password"
            placeholder="senha"
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <div>
          <button className="btn-grad" onClick={handleLogin}>
            Acessar painel
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;
