import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Button, Card, Container, Grid } from '@mui/material';

import Footer from 'src/components/Footer';

import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import { useEffect, useState } from 'react';
import * as Actions from '../../store/softwares/actions';
import Swal from 'sweetalert2';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Software, SoftwaresTypes } from 'src/store/softwares/types';

function Softwares() {
  const dispatch = useDispatch();
  const softwaresData = useSelector(
    (state: ApplicationState) => state?.softwares?.data
  ) as Software[];

  const [rows, setRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    dispatch({ type: SoftwaresTypes.LOAD_SOFTWARES });
  }, []);

  useEffect(() => {
    if (softwaresData?.length > 0) {
      setRows(softwaresData);
    }
  }, [softwaresData]);

  const navigate = useNavigate();
  const handleEdit = (id) => navigate(`/software/${id}`);
  const handleInclude = () => navigate(`/software`);
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja excluir o item?',
      showDenyButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonColor: 'primary',
      icon: 'question',
      denyButtonText: `Cancelar`,
      denyButtonColor: 'secondary'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.deleteSoftware({ id }));
        const filteredSoftwares = rows.filter((software) => {
          return software.id !== id;
        });
        setRows(filteredSoftwares);
        Swal.fire(
          'Excluido!',
          'O item foi excluido com sucesso',
          'success'
        );
      }
    });
  };

  const columns: GridColDef[] = [
    { field: 'pt_name', headerName: 'Nome (PT)', width: 250 },
    { field: 'path', headerName: 'Nome arquivo', width: 250 },
    {
      field: 'image',
      headerName: 'Arquivo',
      headerAlign: 'center',
      align: 'center',
      width: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <img src={`https://api.cayosparapani.com/images/${params.row.path}`} height="150px" style={{objectFit: "cover", width: "25vh"}} alt="" />
          </div>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      align: 'right',
      headerAlign: 'right',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={(event) => handleEdit(id)}
            color="inherit"
            key={`${id}-edit`}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(event) => handleDelete(id)}
            color="inherit"
            key={`${id}-delete`}
          />
        ];
      }
    }
  ];

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Softwares</title>
      </Helmet>
      <PageTitleWrapper>
        <div style={{display: 'flex'}}>
        <PageTitle
          heading="Listagem de Softwares"
          subHeading="Os softwares são utilizados para categorizar projetos"
        />
        <Button
              variant="contained"
              sx={{width: '250px'}}
              onClick={handleInclude}
              endIcon={<AddCircleOutlineIcon />}
              color="success"
            >
              Novo item
            </Button>
        </div>

      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <div style={{ padding: '15px' }}>
                <DataGrid rows={rows} columns={columns} rowHeight={130} autoHeight />
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Softwares;
