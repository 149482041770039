import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { ChangeEvent, useEffect, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Tab,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  CardHeader,
  Divider,
  Switch
} from '@mui/material';
import Swal from 'sweetalert2';

import Footer from 'src/components/Footer';

import { ArrowForwardTwoTone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import * as Actions from '../../store/projects/actions';
import { useParams } from 'react-router-dom';
import {
  Project as ProjectType,
  ProjectDetail
} from 'src/store/projects/types';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { TabList } from '@mui/lab';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Select from 'react-select';
import { CategoriesTypes, Category } from 'src/store/categories/types';
import { Software, SoftwaresTypes } from 'src/store/softwares/types';

function Project() {
  const dispatch = useDispatch();
  const projects = useSelector(
    (state: ApplicationState) => state.projects.data
  ) as ProjectType[];
  const categories = useSelector(
    (state: ApplicationState) => state.categories.data
  ) as Category[];
  const softwares = useSelector(
    (state: ApplicationState) => state.softwares.data
  ) as Software[];
  const { id } = useParams();
  const [project, setProject] = useState<ProjectType>();
  const [originalName, setOriginalName] = useState<string>('novo projeto*');
  const [activeTab, setActiveTab] = useState('pt');

  const [ptData, setPTData] = useState<ProjectDetail>();
  const [enData, setENData] = useState<ProjectDetail>();
  const [images, setImages] = useState<FileList>();
  const [rows, setRows] = useState<GridRowsProp>([]);

  const [thumbnailFile, setThumbnailFile] = useState<File>();

  const [categoryList, setCategoryList] = useState();
  const [defaultCategories, setDefaultCategories] = useState();
  const [projectCategories, setProjectCategories] = useState();
  const [projectHome, setProjectHome] = useState<boolean>(false);
  const [softwareList, setSoftwareList] = useState();
  const [defaultSoftwares, setDefaultSoftwares] = useState();
  const [projectSoftwares, setProjectSoftwares] = useState();

  const handleChange = (event: React.SyntheticEvent, tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    dispatch({ type: CategoriesTypes.LOAD_CATEGORIES });
    dispatch({ type: SoftwaresTypes.LOAD_SOFTWARES });
  }, []);

  useEffect(() => {
    if (categories?.length > 0) {
      let formatedCategories: any = [];
      let defaultProjectCategories: any = [];

      categories.forEach((category, index) => {
        formatedCategories.push({
          value: category.id,
          label: category.pt_name
        });

        if (project?.categories?.length > 0) {
          project.categories.forEach((projectCategory) => {
            if (projectCategory.id === category.id) {
              defaultProjectCategories.push(formatedCategories[index]);
            }
          });
        }
      });

      setCategoryList(formatedCategories);
      setDefaultCategories(defaultProjectCategories);
    }
  }, [categories]);

  useEffect(() => {
    if (softwares?.length > 0) {
      let formatedSoftwares: any = [];
      let defaultProjectSoftwares: any = [];

      softwares.forEach((software, index) => {
        formatedSoftwares.push({
          value: software.id,
          label: software.pt_name
        });

        if (project?.softwares?.length > 0) {
          project.softwares.forEach((projectSoftware) => {
            if (projectSoftware.id === software.id) {
              defaultProjectSoftwares.push(formatedSoftwares[index]);
            }
          });
        }
      });

      setSoftwareList(formatedSoftwares);
      setDefaultSoftwares(defaultProjectSoftwares);
    }
  }, [softwares]);

  useEffect(() => {
    if (projects?.length > 0) {
      const result = projects.find((project) => {
        return project.id === id;
      });
      if (result) {
        setOriginalName(result?.pt?.name);
        setProject(result);
        setPTData(result?.pt);
        setENData(result?.en);
        setRows(result?.images);
        setProjectHome(result?.home);
      }
    }
  }, [projects]);

  const handleSwitch = (event, switcher) => {
    setProjectHome(switcher);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja excluir o item?',
      showDenyButton: true,
      confirmButtonText: 'Excluir',
      confirmButtonColor: 'primary',
      icon: 'question',
      denyButtonText: `Cancelar`,
      denyButtonColor: 'secondary'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(Actions.removeImage({ id: project.id, imageID: id }));
        const filteredImages = rows.filter((image) => {
          return image.id !== id;
        });
        setRows(filteredImages);
        Swal.fire('Excluido!', 'O item foi excluido com sucesso', 'success');
      }
    });
  };

  const columns: GridColDef[] = [
    { field: 'path', headerName: 'Nome arquivo', width: 250 },
    { field: 'columns', headerName: 'Colunas', width: 70, editable: true },
    { field: 'height', headerName: 'Altura', width: 70, editable: true },
    { field: 'position', headerName: 'Posição', width: 70, editable: true },
    {
      field: 'image',
      headerName: 'Arquivo',
      width: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <img
              src={`https://api.cayosparapani.com/images/${params.row.path}`}
              height="300px"
              style={{ objectFit: 'cover', width: '80vh' }}
              alt=""
            />
          </div>
        );
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: 120,
      cellClassName: 'actions',
      align: 'center',
      headerAlign: 'center',
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={(event) => handleDelete(id)}
            color="inherit"
            key={`${id}-delete`}
          />
        ];
      }
    }
  ];

  const handleFileInput = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    setImages(files);
  };

  const handleFileThumbnail = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    setThumbnailFile(file);
  };

  const handleUpdate = () => {
    if (project?.id) {
      dispatch(
        Actions.saveProject({
          body: {
            id: project.id,
            en: enData,
            pt: ptData,
            softwares: projectSoftwares,
            categories: projectCategories,
            images: rows,
            home: projectHome
          },
          newImages: images,
          thumbnail: thumbnailFile
        })
      );
    } else {
      dispatch(
        Actions.insertProject({
          body: {
            id: null,
            en: enData,
            pt: ptData,
            softwares: projectSoftwares,
            categories: projectCategories,
            images: rows,
            home: projectHome
          },
          newImages: images,
          thumbnail: thumbnailFile
        })
      );
    }
    Swal.fire({
      title: 'Sucesso!',
      text: 'Os dados foram atualizados com sucesso',
      icon: 'success',
      confirmButtonText: 'Feito'
    }).then(() => {
      history.back();
    });
  };

  const updateRowColumns = (newRow) => {
    for (const row of rows) {
      if (row?.id === newRow?.id) {
        row.columns = newRow.columns;
        row.height = newRow.height;
        row.position = newRow.position;
      }
    }
    setRows(rows);
  };

  const updateRowError = (error) => {};

  const updateCategory = (categoryList, param) => {
    let newCategoryList: any = [];
    categoryList?.forEach((category) => {
      newCategoryList.push({
        id: category.value
      });
    });
    setProjectCategories(newCategoryList);
  };

  const updateSoftware = (softwareList, param) => {
    let newSoftwareList: any = [];
    softwareList?.forEach((software) => {
      newSoftwareList.push({
        id: software.value
      });
    });
    setProjectSoftwares(newSoftwareList);
  };

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Projeto</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Projeto"
          subHeading="Os projetos possuem suporte a inglês e português, sendo as imagens compartilhadas para ambos idiomas"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <CardHeader title={`Projeto: ${originalName}`} />

                  <TabContext value={activeTab}>
                    <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                      <TabList onChange={handleChange} aria-label="Linguaguem">
                        <Tab label="Português" value="pt" />
                        <Tab label="Inglês" value="en" />
                        <Tab label="Imagens" value="img" />
                        <Tab label="Categorias" value="cat" />
                        <Tab label="Softwares" value="sof" />
                        <Tab label="Thumbnail & Destaque" value="tmb" />
                      </TabList>
                    </Box>

                    <TabPanel value="pt">
                      <div>
                        <TextField
                          id="pt-name"
                          label="Nome (PT)"
                          type="text"
                          value={ptData?.name || ''}
                          onChange={(event) =>
                            setPTData({
                              ...ptData,
                              name: event.target.value
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div>
                        <TextField
                          id="pt-vimeo"
                          label="Vimeo (PT)"
                          type="text"
                          value={ptData?.vimeo || ''}
                          onChange={(event) =>
                            setPTData({
                              ...ptData,
                              vimeo: event.target.value
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div>
                        <TextField
                          id="pt-description"
                          label="Descrição (PT)"
                          type="text"
                          multiline
                          rows={10}
                          value={ptData?.description || ''}
                          onChange={(event) =>
                            setPTData({
                              ...ptData,
                              description: event.target.value
                            })
                          }
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div>
                        <label
                          style={{
                            paddingLeft: '25px',
                            color: 'rgba(34, 51, 84, 0.7)'
                          }}
                          htmlFor="pt-credit"
                        >
                          Créditos (PT)
                        </label>
                      </div>
                      <div
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        <CKEditor
                          key={'pt-credit'}
                          editor={ClassicEditor}
                          data={ptData?.credits || ''}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setPTData({
                              ...ptData,
                              credits: data
                            });
                          }}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value="en">
                      <Box>
                        <div>
                          <TextField
                            id="en-name"
                            label="Nome (EN)"
                            type="text"
                            value={enData?.name || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                name: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="en-vimeo"
                            label="Vimeo (EN)"
                            type="text"
                            value={enData?.vimeo || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                vimeo: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <TextField
                            id="en-description"
                            label="Descrição (EN)"
                            type="text"
                            multiline
                            rows={10}
                            value={enData?.description || ''}
                            onChange={(event) =>
                              setENData({
                                ...enData,
                                description: event.target.value
                              })
                            }
                            InputLabelProps={{ shrink: true }}
                          />
                        </div>
                        <div>
                          <label
                            style={{
                              paddingLeft: '25px',
                              color: 'rgba(34, 51, 84, 0.7)'
                            }}
                            htmlFor="en-credit"
                          >
                            Créditos (EN)
                          </label>
                        </div>
                        <div
                          style={{ paddingLeft: '10px', paddingRight: '10px' }}
                        >
                          <CKEditor
                            key={'en-credit'}
                            editor={ClassicEditor}
                            data={enData?.credits || ''}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setENData({
                                ...enData,
                                credits: data
                              });
                            }}
                          />
                        </div>
                      </Box>
                    </TabPanel>
                    <TabPanel value="img">
                      <Box>
                        <Card sx={{ padding: '20px', marginBottom: 2 }}>
                          <div style={{ paddingBottom: '5px' }}>
                            <label
                              style={{
                                paddingLeft: '25px',
                                paddingBottom: '5px',
                                color: 'rgba(34, 51, 84, 0.7)'
                              }}
                              htmlFor="new-images"
                            >
                              Novas imagens
                            </label>
                          </div>
                          <div>
                            <input
                              style={{
                                paddingLeft: '25px',
                                color: 'rgba(34, 51, 84, 0.7)'
                              }}
                              id="new-images"
                              type="file"
                              multiple
                              onChange={handleFileInput}
                            />
                          </div>
                        </Card>
                        <DataGrid
                          style={{ display: id?.length > 0 ? 'flex' : 'none' }}
                          rows={rows}
                          columns={columns}
                          rowHeight={250}
                          autoHeight
                          processRowUpdate={updateRowColumns}
                          onProcessRowUpdateError={updateRowError}
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel value="cat">
                      <div style={{ minHeight: '300px' }}>
                        <Select
                          isMulti
                          name="categories"
                          options={categoryList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={updateCategory}
                          defaultValue={defaultCategories}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value="sof">
                      <div style={{ minHeight: '300px' }}>
                        <Select
                          isMulti
                          name="softwares"
                          options={softwareList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={updateSoftware}
                          defaultValue={defaultSoftwares}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value="tmb">
                      <div style={{ minHeight: '300px' }}>
                        <Box>
                          <Card sx={{ padding: '20px', marginBottom: 2 }}>
                            <div style={{ paddingBottom: '5px' }}>
                              <label
                                style={{
                                  paddingLeft: '25px',
                                  paddingBottom: '5px',
                                  color: 'rgba(34, 51, 84, 0.7)'
                                }}
                                htmlFor="thumbnail"
                              >
                                Thumbnail atual
                              </label>
                            </div>
                            <div>
                              <img
                                src={`http://api.cayosparapani.com/images/${project?.thumbnail}`}
                                height="300px"
                                style={{ objectFit: 'cover', width: '80vh' }}
                                alt=""
                              />
                            </div>
                          </Card>
                          <div
                            style={{ paddingBottom: '5px', paddingTop: '25px' }}
                          >
                            <label
                              style={{
                                paddingLeft: '25px',
                                paddingBottom: '5px',
                                color: 'rgba(34, 51, 84, 0.7)'
                              }}
                              htmlFor="thumbnail"
                            >
                              Nova thumbnail
                            </label>
                          </div>
                          <div>
                            <input
                              style={{
                                paddingLeft: '25px',
                                color: 'rgba(34, 51, 84, 0.7)'
                              }}
                              id="thumbnail"
                              type="file"
                              multiple
                              onChange={handleFileThumbnail}
                            />
                          </div>

                          <div style={{ paddingTop: '20px' }}>
                            <label
                              style={{
                                paddingLeft: '25px',
                                paddingBottom: '5px',
                                color: 'rgba(34, 51, 84, 0.7)'
                              }}
                              htmlFor="home-switch"
                            >
                              Projeto destaque? (home)
                            </label>
                          </div>
                          <div style={{ paddingLeft: '16px' }}>
                            <Switch
                              color="primary"
                              checked={projectHome}
                              onChange={handleSwitch}
                              name="home-switch"
                            />
                          </div>
                        </Box>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleUpdate}
              endIcon={<ArrowForwardTwoTone />}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Project;
