/* eslint default-param-last: ["error"] */
import { Reducer } from 'redux';
import { GeneralState, GeneralTypes } from './types';

const INITIAL_STATE: GeneralState = {
  data: {
    en: {},
    pt: {},
    id: ''
  },
  error: false,
  loading: false
};

function reducer(
  state = INITIAL_STATE,
  action: any = { type: '', payload: '' }
): GeneralState {
  switch (action.type) {
    case GeneralTypes.LOAD_GENERAL:
      return {
        ...state,
        loading: true
      };
    case GeneralTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case GeneralTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    case GeneralTypes.SAVE_GENERAL:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case GeneralTypes.SAVE_GENERAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload
      };
    case GeneralTypes.SAVE_GENERAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: null
      };
    default:
      return state;
  }
}

export default reducer;
