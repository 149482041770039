import {
  styled,
  Avatar
} from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/" style={{textAlign: 'center'}}>
        <Avatar
          sx={{ width: 100, height: 75 }}
          src="/static/images/logo/logo.png"
        />
    </LogoWrapper>
  );
}

export default Logo;
