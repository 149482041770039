import {
  LoginState,
  LoginTypes,
} from './types';

const INITIAL_STATE: LoginState = {
  loggedIn: false
};

function reducer(
  state = INITIAL_STATE,
  action: any = { type: '', payload: '' }
): LoginState {
  switch (action.type) {
    case LoginTypes.LOGIN:
      return {
        ...state,
        loggedIn: true
      };
    case LoginTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false
      };
    default:
      return state;
  }
}
export default reducer;