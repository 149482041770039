import { Box, IconButton, Tooltip } from '@mui/material';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';
import ExitIcon from '@mui/icons-material/ExitToApp';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Actions from '../../../../store/login/actions';

function HeaderButtons() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const handleLogout = () => {
    dispatch(Actions.logout(false));
    navigate(`/login`);
  }

  return (
    <Box sx={{ mr: 1 }}>
      <Tooltip arrow title="Sair" placement="right">
      <IconButton aria-label="delete" sx={{ margin: 1 }} onClick={handleLogout}>
        <ExitIcon fontSize="large" color="primary"/>
      </IconButton>
      </Tooltip>
    </Box>
  );
}

export default HeaderButtons;
