import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useEffect, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Container,
  Grid,
  Tab,
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  CardHeader
} from '@mui/material';
import Swal from 'sweetalert2';

import Footer from 'src/components/Footer';

import { ArrowForwardTwoTone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ApplicationState } from 'src/store/rootReducer';
import * as Actions from '../../store/categories/actions';
import { useParams } from 'react-router-dom';
import { Category as CategoryType } from 'src/store/categories/types';

function Category() {

  const dispatch = useDispatch();
  const categories = useSelector(
    (state: ApplicationState) => state.categories.data
  ) as CategoryType[];
  const { id } = useParams();
  const [category, setCategory] = useState<CategoryType>()
  const [originalName, setOriginalName] = useState<string>('nova categoria*')

  useEffect(() => {
    if(categories?.length > 0){
      const result = categories.find(category => {
        return category.id === id
      })
      setOriginalName(result?.pt_name)
      setCategory(result)
    }
  }, [categories]);

  const handleUpdate = () => {
    if(category?.id){
      dispatch(
        Actions.saveCategory({
          ...category
        })
      );
    }else{
      dispatch(
        Actions.insertCategory({
          ...category
        })
      );
    }
    Swal.fire({
      title: 'Sucesso!',
      text: 'Os dados foram atualizados com sucesso',
      icon: 'success',
      confirmButtonText: 'Feito'
    }).then(() => {
      history.back()
    } )
  };

  return (
    <>
      <Helmet>
        <title>Mioca Admin | Categoria</title>
      </Helmet>
      <PageTitleWrapper>
        <PageTitle
          heading="Categoria"
          subHeading="É possível diferencias os nomes em português e inglês, mantendo a mesma cor de identificação"
        />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Box
                  component="form"
                  sx={{
                    '& .MuiTextField-root': { m: 1, width: '98%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <CardHeader title={`Categoria: ${originalName}`}/>

                  <div>
                    <TextField
                      id="category-name-pt"
                      label="Nome (PT)"
                      type="text"
                      value={category?.pt_name || ''}
                      onChange={(event) =>
                        setCategory({
                          ...category,
                          pt_name: event.target.value
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="category-name-en"
                      label="Nome (EN)"
                      type="text"
                      value={category?.en_name || ''}
                      onChange={(event) =>
                        setCategory({
                          ...category,
                          en_name: event.target.value
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="category-color"
                      label="Cor"
                      type="text"
                      value={category?.color || ''}
                      onChange={(event) =>
                        setCategory({
                          ...category,
                          color: event.target.value
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div>
                    <TextField
                      id="category-slug"
                      label="Slug (URL)"
                      type="text"
                      value={category?.slug || ''}
                      onChange={(event) =>
                        setCategory({
                          ...category,
                          slug: event.target.value
                        })
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleUpdate}
              endIcon={<ArrowForwardTwoTone />}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Category;
